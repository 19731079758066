<template>
  <!--
    The view for the ThemeOverview-component
  -->
  <Portlet 
    :title="$t('menu.themes')" 
    class="themeOverview"
    icon="clone"
  >
    <template slot="buttons">
      <button 
        class="btn btn-sm btn-primary float-right mt-2" 
        @click="addTheme()"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
      <div class="clearfix" />
    </template>
    <ThemeList
      ref="themeList"
      @reloadAuditLogs="reloadAuditLogs"
    />
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :is-themes="true"
      @reload="reloadCutConfigList"
    /> 
  </Portlet>
</template>

<script>
export default {
  name: "ThemeOverview",
  components: {
    ThemeList: () => import('@/components/VideoPostprocessing/Theme/ThemeList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  metaInfo () {
    return {
      title: this.$t('videoPostProcessing.title')
    }
  },
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadCutConfigList () {
      this.$refs.themeList.reloadThemeAndCloseSidebar();
    },
    addTheme () {
      this.$refs.themeList.showAddTheme();
    }
  }
}
</script>
